<template>
  <div class="publish-look-agents-options">
    <div class="options">
      <div class="option">
        <div class="header">
          <div class="title">
            {{ $t('publishLook.publishingOptions.toFeed.label') }}
          </div>
          <base-checkbox :value="toFeed"
                         @input="$emit('update:toFeed', $event)"></base-checkbox>
        </div>
        <div class="description">
          {{ $t('publishLook.publishingOptions.toFeed.description') }}
        </div>
      </div>

      <div class="option">
        <div class="header">
          <div class="title">
            {{ $t('publishLook.publishingOptions.toClients.label') }}
          </div>
          <base-checkbox :value="toClients"
                         @input="$emit('update:toClients', $event)"></base-checkbox>
        </div>

        <div class="description">
          {{ $t('publishLook.publishingOptions.toClients.description') }}
        </div>
      </div>

      <div class="option option-draft">
        <div class="header">
          <div class="title">
            {{ $t('publishLook.publishingOptions.toDrafts.label') }}
          </div>
          <base-checkbox :value="toDrafts"
                         @input="$emit('update:toDrafts', $event)"></base-checkbox>
        </div>

        <div class="description">
          {{ $t('publishLook.publishingOptions.toDrafts.description') }}
        </div>
      </div>
    </div>

    <div class="client-list"
         v-if="toClients">
      <div class="search">
        <base-text-box v-model="clientSearch"
                       :placeholder="$t('publishLook.publishingOptions.toClients.searchPlaceholder')"/>
      </div>
      <vue-perfect-scrollbar>
        <ul class="list">
          <li v-for="client in filteredClients"
              :key="client.id"
              class="client"
              :class="{ selected: client.selected }"
              @click="onClientClick(client)">
            <base-avatar :user="client" class="avatar"/>
            <div class="name">{{ client.fullName }}</div>
          </li>
        </ul>
      </vue-perfect-scrollbar>
    </div>

    <div class="hint" v-if="toClients && selectedClients.length">
      {{ $t('publishLook.publishingOptions.toClients.clientsList') }}
      {{ selectedClients.map(client => client.fullName).join(', ') }}
    </div>
  </div>
</template>

<script>
  import clientService from '@/services/queries/clientQueries.js'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'

  export default {
    props: {
      toFeed: {
        required: true
      },

      toClients: {
        required: true
      },

      toDrafts: {
        required: true
      }
    },

    components: {
      VuePerfectScrollbar
    },

    data() {
      return {
        clients: [],
        clientSearch: ''
      }
    },

    created() {
      this.loadClients()
    },

    methods: {
      loadClients() {
        let params = {
          sorting: 'time_created{desc}'
        }

        return clientService.get(params).then(data => {
          this.clients = data.map(client => {
            return {...client, ...{ selected: false }}
          })
        })
      },

      onClientClick(client) {
        client.selected = !client.selected

        this.$emit('clientsUpdated', this.selectedClients)
      }
    },

    computed: {
      selectedClients() {
        return this.clients.filter(client => client.selected)
      },

      filteredClients() {
        if (this.clientSearch) {
          let search = this.clientSearch.toLowerCase().trim()
          return this.clients.filter(client => client.fullName.toLowerCase().includes(search))
        } else {
          return this.clients
        }
      }
    },

    watch: {
      toDrafts(value) {
        if (value) {
          this.$emit('update:toClients', false)
          this.$emit('update:toFeed', false)
        }
      },

      toClients(value) {
        if (value) {
          this.$emit('update:toDrafts', false)
        }
      },

      toFeed(value) {
        if (value) {
          this.$emit('update:toDrafts', false)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>


  .publish-look-agents-options {
    .options {
      display: flex;
    }

    .option {
      width: 280px;
      height: 150px;
      padding: 20px;
      border: 1px solid #F4F4F4;
      border-radius: 4px;
      margin-right: 20px;

      &.option-draft {
        margin-left: auto;
      }

      .header {
        display: flex;
        justify-content: space-between;

        .title {
          font-size: 20px;
          color: #495057;
          margin-bottom: 10px;
        }
      }

      .description {
        color: rgba(28, 28, 28, 0.5);
        font-size: 16px;
        letter-spacing: -0.35px;
        width: 200px;
        line-height: 22px;
      }
    }

    .client-list {
      width: 100%;
      height: 400px;
      margin-top: 20px;

      border: 1px solid #F4F4F4;

      .search {
        height: 100px;
        padding: 20px;
      }

      .list {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        height: 300px;
        overflow: auto;
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      .client {
        width: 300px;
        display: flex;
        align-items: center;
        padding: 20px;
        cursor: pointer;

        &:hover, &.selected {
          .name {
            color: $primary-color;
          }
        }

        .avatar {
          flex-shrink: 0;
        }

        .name {
          width: 200px;
          margin-left: 10px;
          color: #495057;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .hint {
      margin-top: 20px;
      font-size: 14px;
      line-height: 18px;
      color: #797979;
    }
  }
</style>
