<template>
  <div class="publish-look">
    <div class="wrapper">
      <publish-look-header />

      <ValidationObserver ref="observer"
                          slim>
        <div class="content">
          <div class="top">
          <div class="left">
            <div class="form-group">
              <label for="name" class="label">
                {{ $t('publishLook.title') }}
              </label>

              <base-text-box id="name"
                             v-model="title"
                             ref="title"
                             name="title"
                             :placeholder="$t('publishLook.titlePlaceholder')"
                             rules="required|max:200"
                             :autofocus="true"
                             :max-chars="200"
                             :show-chars-counter="true" />
            </div>

            <div class="form-group">
              <label for="description" class="label">
                {{ $t('publishLook.description') }}
              </label>

              <base-text-box id="description"
                             v-model="description"
                             ref="description"
                             name="description"
                             :placeholder="$t('publishLook.descriptionPlaceholder')"
                             rules="required|max:1500"
                             :multi-line="true"
                             :max-chars="1500"
                             :show-chars-counter="true"
                             :rows="10"></base-text-box>
            </div>
          </div>

          <div class="preview">
            <img :src="previewPicture" v-if="previewPicture">
          </div>
        </div>

          <div class="form-group">
            <label class="label has-bottom-space">
              {{ $t('publishLook.lookContext') }}
            </label>
            <base-select-tag v-model="lookContext"
                             :options="$store.handbooks.lookContexts"
                             :keys="{ label: 'title', value: 'id' }"
                             :can-be-empty="true"></base-select-tag>
          </div>

          <div class="form-group" v-if="!$currentUser.nickName">
            <label class="label">
              {{ $t('publishLook.nickname.label') }}
            </label>
            <base-text-box v-model="nickname"
                           name="nickname"
                           ref="nickname"
                           :placeholder="$t('publishLook.nickname.placeholder')"
                           rules="required|nickname_unique" />
            <div class="hint">
              {{ $t('publishLook.nickname.hint') }}
            </div>
          </div>

          <div v-if="$currentUser.isAgent"
               class="form-group">
            <label class="label has-bottom-space">
              {{ $t('publishLook.whereToPublish') }}
            </label>
            <publish-look-agents-options
                    :toFeed.sync="toFeed"
                    :toClients.sync="toClients"
                    :toDrafts.sync="toDrafts"
                    @clientsUpdated="onClientsUpdated"/>
          </div>

          <publish-look-bottom-agent v-if="$currentUser.isAgent"
                                     :publishing="publishing"
                                     :button-disabled="isPublishingButtonDisabled"
                                     @publish="onPublishClick"/>

          <publish-look-bottom-client v-if="$currentUser.isClient || $currentUser.isStylist"
                                      :savingDraft="savingDraft"
                                      :publishing="publishing"
                                      @publish="onPublishClick"
                                      @saveDraft="onSaveAsDraftClick"/>
      </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import PublishLookHeader          from './PublishLookHeader.vue'
  import PublishLookAgentsOptions from './PublishLookAgentsOptions.vue'
  import PublishLookBottomClient    from './PublishLookBottomClient.vue'
  import PublishLookBottomAgent   from './PublishLookBottomAgent.vue'

  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import hasBrandBackgroundMixin from '@/services/mixins/hasBrandBackgroundMixin.js'
  import lookService from '@/services/queries/lookQueries.js'
  import profileService from '@/services/queries/profileQueries.js'
  import { getImageFromUrl } from '@/services/utils'

  export default {
    props: {
      id: {
        required: false
      },
      fromStudio: {
        required: true,
        type: Boolean
      }
    },

    components: {
      PublishLookHeader,
      PublishLookAgentsOptions,
      PublishLookBottomClient,
      PublishLookBottomAgent,
      VuePerfectScrollbar
    },

    data() {
      return {
        mode: this.id ? 'existing' : 'vuex',

        title: '',
        description: '',
        previewPicture: null,
        lookContext: null,
        items: [],
        nickname: '',

        userId: this.$route.query.userId || null,
        publishing: false,
        savingDraft : false,

        toDrafts: false,
        toFeed: false,
        toClients: false,

        clients: []
      }
    },

    mixins: [hasBrandBackgroundMixin],

    // 1) Чувак перешел из студии,    лук существующий    - vuex
    // 2) Чувак перешел из студии,    лук не существующий - vuex
    // 2) Чувак перешел не из студии, лук существующий    - не vuex

    beforeRouteEnter(to, from, next) {
      if (to.params.id) {
        lookService.find(to.query.userId, to.params.id).then(look => {
          getImageFromUrl(look.previewUrl, 'dataUrl').then(previewPicture => {
            next(vm => {
              // If id is specified it doesn't matter where the user came from. In any case
              // we should take title, description, and context from the database.
              vm.title          = look.title
              vm.description    = look.description
              vm.lookContext    = look.lookContext

              // However, if user came from studio, we should take items and picture
              // from vuex because the can be changed directly in studio
              // Otherwise it means that the user edits existing look bypassing the studio step.
              vm.items = to.query.fromStudio ? vm.lookFromVuex.items : look.items
              vm.previewPicture = to.query.fromStudio ? vm.lookFromVuex.previewPicture : previewPicture
            })
          })
        })
      } else {
        next(vm => {
          vm.title          = vm.lookFromVuex.title
          vm.description    = vm.lookFromVuex.description
          vm.lookContext    = vm.lookFromVuex.lookContext
          vm.items          = vm.lookFromVuex.items
          vm.previewPicture = vm.lookFromVuex.previewPicture
        })
      }
    },

    methods: {
      getAttributes(additionalAttributes = {}) {
        let attributes= {
          ...{
            is_public: false,
            is_draft: true,
            title: this.title,
            description: this.description,
            look_context_id: this.lookContext ? this.lookContext.look_context_id : null,
          },
          ...additionalAttributes
        }

        if (this.fromStudio || additionalAttributes.client_id) {
          attributes.items = this.items
        }

        return attributes
      },

      async onPublishClick () {
        const valid = await this.$refs.observer.validate()

        if (!valid) {
          return
        }

        this.publishing = true

        if (this.$currentUser.nickName) {
          this.publish()

          return
        }

        this.saveNickname().then(() => {
          this.publish()
        }).catch(() => {
          this.publishing = false
        })
      },

      publish () {
        if (this.$currentUser.isClient || this.$currentUser.isStylist) {
          this.publishToFeed().then(() => {
            if (this.mode === 'vuex') {
              this.clearLook()
            }

            this.setTotalLooksNumber()
            this.$router.push({ name: 'myLooks', params: { type: 'published' }})
          })
        } else {
          if (this.toDrafts) {
            this.publishToDrafts().then(() => {
              if (this.mode === 'vuex') {
                this.clearLook()
              }

              this.setTotalLooksNumber()
              this.$router.push({ name: 'myLooks', params: { type: 'drafts' }})
            })

            return
          }

          let promises = []

          if (this.toFeed) {
            promises.push(this.publishToFeed())
          }

          if (this.toClients) {
            promises.push(this.publishToClients())
          }

          Promise.all(promises).then(results => {
            if (this.mode === 'vuex') {
              this.clearLook()
            }

            this.setTotalLooksNumber()

            this.$router.push({ name: 'myLooks', params: { type: 'published' }})
          })
        }
      },

      async onSaveAsDraftClick() {
        const valid = await this.$refs.observer.validate()
        console.log('valid', valid)

        if (!valid) {
          return
        }

        this.savingDraft = true

        if (this.$currentUser.nickName) {
          this.publishToDrafts().then(() => {
            if (this.mode === 'vuex') {
              this.clearLook()
            }

            this.setTotalLooksNumber()
            this.$router.push({ name: 'myLooks', params: { type: 'drafts' }})
          })

          return
        }

        this.saveNickname().then(() => {
          this.publishToDrafts().then(() => {
            if (this.mode === 'vuex') {
              this.clearLook()
            }

            this.setTotalLooksNumber()
            this.$router.push({ name: 'myLooks', params: { type: 'drafts' }})
          })
        }).catch(() => {
          this.publishing = false
        })
      },

      publishToFeed() {
        let attributes = this.getAttributes({
          is_public: true,
          is_draft: false
        })

        if (this.mode === 'vuex') {
          return this.storeLook(attributes)
        } else {
          return this.updateLook(this.id, attributes)
        }
      },

      publishToClients() {
        let promises = []

        this.clients.forEach(client => {
          let attributes = this.getAttributes({
            is_public: false,
            is_draft: false,
            client_id: client.id
          })

          let promise = this.storeLook(attributes)

          promises.push(promise)
        })

        return promises
      },

      publishToDrafts() {
        let attributes = this.getAttributes({
          is_public: false,
          is_draft: true
        })

        if (this.mode === 'vuex') {
          return this.storeLook(attributes)
        } else {
          return this.updateLook(this.id, attributes)
        }
      },

      onClientsUpdated(clients) {
        this.clients = clients
      },

      storeLook(attributes) {
        return lookService.store('me', attributes).then(response => {
          let id = response.look_id

          return lookService.storePreview('me', id, this.previewPicture)
        })
      },

      updateLook(id, attributes) {
        if (this.fromStudio) {
          return lookService.update('me', id, attributes).then(response => {
            let id = response.look_id

            return lookService.storePreview('me', id, this.previewPicture)
          })
        } else {
          return lookService.update('me', id, attributes)
        }
      },

      updateVuex(key, data) {
        this.setLook(Object.assign({}, this.lookFromVuex, { [key]: data }))
      },

      saveNickname() {
        return profileService.update(this.$currentUser.id, { nick_name: this.nickname }).then(() => {
          return this.updateCurrentUser()
        })
      },

      ...mapActions('studio', [
        'setLook',
        'clearLook'
      ]),

      ...mapActions('looks', [
        'setTotalLooksNumber'
      ]),

      ...mapActions('auth', [
        'updateCurrentUser'
      ])
    },

    computed: {
      isPublishingButtonDisabled() {
        return this.$currentUser.isAgent && !this.toFeed && !this.toClients && !this.toDrafts
      },

      ...mapGetters('studio', {
        lookFromVuex: 'getLook'
      })
    },

    watch: {
      title(value) {
        if (this.mode === 'vuex') {
          this.updateVuex('title', value)
        }
      },

      description(value) {
        if (this.mode === 'vuex') {
          this.updateVuex('description', value)
        }
      },

      lookContext(value) {
        if (this.mode === 'vuex') {
          this.updateVuex('lookContext', value)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  @import "~@/assets/scss/mixins.scss";

  .publish-look {
    height: 100%;

    .wrapper {
      width: 1024px;
      height: 100%;
      margin: 0 auto;
      padding-top: 40px;

      & > .content {
        width: 100%;
        padding: 40px;
        margin-bottom: 40px;
        background: #fff;
        box-shadow: 0 6px 12px 0 rgba(54,76,106,0.20);
        border-radius: 8px;
      }

      .top {
        display: flex;
        width: 100%;

        .left {
          flex: 1 0;
        }

        .preview {
          width: 400px;
          margin-left: 100px;

          img {
            border: 1px solid #eee;
            width: 100%;
          }
        }
      }

      .form-group {
        width: 100%;
        margin-bottom: 40px;

        .label {
          display: block;
          font-weight: bold;
          font-size: 22px;
          letter-spacing: -0.5px;
          color: $primary-color;

          &.has-bottom-space {
            margin-bottom: 20px;
          }
        }

        .hint {
          font-size: 12px;
          color: #797979;
        }
      }
    }
  }
</style>
