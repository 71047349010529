<template>
  <div class="publish-look-header">
    <a href="#" @click.prevent="$router.go(-1)">
      {{ $t('publishLook.header.back') }}
    </a>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
  .publish-look-header {
    margin-bottom: 20px;

    a {
      color: #fff;
      font-size: 16px;
    }
  }
</style>