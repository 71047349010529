<template>
  <div class="publish-look-bottom-agents">
    <base-button :disabled="buttonDisabled"
                 :loading="publishing"
                 @click="$emit('publish')">
      {{ $t('publishLook.bottom.agent.publish') }}
    </base-button>
  </div>
</template>

<script>
  export default {
    props: {
      publishing: {
        required: false,
        type: Boolean,
        default: false
      },

      buttonDisabled: {
        required: false,
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .publish-look-bottom-client {

  }
</style>
