<template>
  <div class="publish-look-bottom-client">
    <base-button :loading="publishing"
                 @click="$emit('publish')">
      {{ $t('publishLook.bottom.client.publish') }}
    </base-button>

    <div class="save-as-draft"
         @click="$emit('saveDraft')">
      {{ savingDraft ?
        $t('publishLook.bottom.client.saveAsDraft.onLoad') :
        $t('publishLook.bottom.client.saveAsDraft.beforeSave') }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      savingDraft: {
        required: false,
        type: Boolean,
        default: false
      },

      publishing: {
        required: false,
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>


  .publish-look-bottom-client {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .save-as-draft {
      color: #797979;
      cursor: pointer;

      &:hover {
        color: $primary-color;
      }
    }
  }
</style>
